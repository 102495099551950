import React, { useEffect, useState } from "react";
import styles from "./StatusEditor.module.css";
import { useMedia, useMediaUpdate } from "../../contexts/MediaContext";

import { StatusDropdown } from "../StatusDropdown/StatusDropdown";
import { StatusDropdownItem } from '../StatusDropdown/StatusDropdownItem';
import { patchUsermedia, removeUsermediaFromLibrary } from "../../api/Api";

export const StatusEditor = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    const mediaItem = useMedia();
    const updateMediaItem = useMediaUpdate();

    let token = localStorage.getItem("token");

    const [stageToDelete, setStageToDelete] = useState();
    const [selectedLibrary, setSelectedLibrary] = useState(mediaItem.status);
    const [removing, setIsRemoving] = useState(false);

    // ----------------------------------------------------- PRE-RENDER
    var statusDict = {};
    switch (mediaItem.type) {
        // mappings of backend status values to display values
        case "book": 
            statusDict = {
                "read": "Read",
                "DNF": "Did Not Finish",
                "TBR": "To Be Read",
                "currentlyReading": "Currently Reading",
                "watch": "Watch List",
                "library": "In Library"
            }
            break;
        case "tvshow" || "movie" :
            statusDict = {
                "read": "Seen",
                "DNF": "Did Not Finish",
                "TBR": "To Be Watched",
                "currentlyReading": "Currently Watching",
                "watch": "Not Out Yet",
                "library": "In Library"
            }
            break;
        case "album" :
            statusDict = { 
                "read": "Listened",
                "DNF": "Did Not Finish",
                "TBR": "To Be Heard",
                "currentlyReading": "Currently Listening",
                "watch": "Watch List",
                "library": "In Library"
            }
            break;
        case "videogame":
            statusDict = { 
                "read": "Played",
                "DNF": "Did Not Finish",
                "TBR": "To Be Played",
                "currentlyReading": "Currently Playing",
                "watch": "Watch List",
                "library": "In Library"
            }
            break;
        default: 
            statusDict = {
                "read": "Read",
                "DNF": "Did Not Finish",
                "TBR": "To Be Read",
                "currentlyReading": "Currently Reading",
                "watch": "Watch List",
                "library": "In Library"
            }
    }

    let allOptions = [ // all avaliable statuses
        <StatusDropdownItem key="library" displayValue={statusDict["library"]} onClick={() => handleStatusChange("library")}></StatusDropdownItem>,
        <StatusDropdownItem key="TBR" displayValue={statusDict["TBR"]} onClick={() => handleStatusChange("TBR")}></StatusDropdownItem>,
        <StatusDropdownItem key="currentlyReading" displayValue={statusDict["currentlyReading"]} onClick={() => handleStatusChange("currentlyReading")}></StatusDropdownItem>,
        <StatusDropdownItem key="read" displayValue={statusDict["read"]} onClick={() => handleStatusChange("read")}></StatusDropdownItem>,
        <StatusDropdownItem key="DNF" displayValue={statusDict["DNF"]} onClick={() => handleStatusChange("DNF")}></StatusDropdownItem>,
        <StatusDropdownItem key="delete" displayValue="Delete From Library" onClick={handleDelete}></StatusDropdownItem>,
    ];

    // only lets user select watch if book not out yet
    if (new Date(mediaItem.pub) > new Date) {
        allOptions.splice(1, 0, <StatusDropdownItem key="watch" displayValue="Watch List" onClick={() => handleStatusChange("watch")}></StatusDropdownItem>)
    }

    function isInList(i) {
        // checks if Dropdown item is currently selected and excludes it from options
        return (i.key !== selectedLibrary);
    }

    const displayOptions = allOptions.filter(isInList);

    async function handleStatusChange(newStatus) {
        // sends new status to backend and updates tvshowItem

        setSelectedLibrary(newStatus); // triggers rerender of dropdown

        await patchUsermedia(mediaItem.usermediaID, {status : newStatus}, token)
            .then((updatedTVShow) => {
                updateMediaItem(updatedTVShow);
            })
            .catch((error) => {
                console.log(error, "failed to update tvshow status");
            });
    }

    async function handleRemoveFromLibrary() {
        // makes call to remove media from library

        await removeUsermediaFromLibrary(mediaItem.usermediaID, token)
            .then((plainMedia) => {
                updateMediaItem(plainMedia);
                setIsRemoving(false);
            })
            .catch((error) => {
                console.log("failed to remove media from userlibrary ", error)
                setIsRemoving(false);
            })
    }

    function handleDelete() {
        // initiates removal of book from a user's library
        setStageToDelete(true);
    }

    function handleCancelDelete() {
        // cancels removal
        setStageToDelete(false);
    }

    function handleConfirmDelete() {
        // removes userbook
        setIsRemoving(true);
        handleRemoveFromLibrary();
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            { mediaItem.usermediaID ? 
                <div className={styles.dropdown}>
                    {stageToDelete ? 
                        <div>
                            <h2 className={styles.message}> Are you sure? </h2>
                            <h5 className={styles.message}> Removing this book will PREMENETLY delete your rating, review, readings, and entries! </h5>
                            <button className={styles.deleteButton} onClick={handleConfirmDelete}>{removing ? "Removing..." : "Confirm DELETE"}</button>
                            <button className={styles.cancelButton} onClick={handleCancelDelete}>Cancel</button>
                        </div>
                        : 
                        <StatusDropdown buttonText={statusDict[selectedLibrary]} content={displayOptions} />}
                </div> 
                :
                <button></button>
            }

        </section>
    )
}