import React, { useEffect, useState } from 'react';
import styles from "./HomeTBR.module.css";

import { getMediaByLibrary } from '../../api/Api';

import { TBRbook } from '../BookTypes/TBRBook/TBRBook';
import { Loader } from "../Loader/Loader";

export const HomeTBR = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");

    const [mediaItems, setMediaItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    // number of media to show
    let numMedia = window.innerWidth > 500 ? Math.floor(window.innerWidth / 188) : Math.floor(window.innerWidth/150); // number of media across based on window size
    let tempCSS = "1fr ";
    const cssModifier = tempCSS.repeat(numMedia)

    const mediaObjects = makeMediaItems();

    useEffect(() => { 
        // gets media on first render
        fetchMedia()
    }, []);

    // ----------------------------------------------------- PRE-RENDER

    async function fetchMedia() {
        // gets books from back

        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        await getMediaByLibrary("TBR", token)
            .then((foundMedia) => {
                setMediaItems(foundMedia);
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("failed to get TBR media", error)
                setIsLoading(false)
            })
    }

    function getRandomDisplay() {
        // gets random indexes and corrosponding books

        // checks that there are enough books
        if (mediaItems.length <= numMedia) {
            return mediaItems;
        }

        // makes random display
        var indexes = [];
        var tempDisplay = []

        //gets number of random indexes for each currenly reading book
        while (indexes.length < numMedia) {
            var r = Math.floor(Math.random() * mediaItems.length - 1) + 1;
            if (indexes.indexOf(r) === -1) { // if not already picked
                indexes.push(r);
                tempDisplay.push(mediaItems[r]);
            }
        }
        return tempDisplay;
    }

    function makeMediaItems() {
        //makes list of books

        let mediaList = getRandomDisplay();

        if (mediaList.length > 0) {
            let tempList = []
            for (let i = 0; i < mediaList.length; i++) {
                tempList.push(
                    <div key={mediaList[i].mediaID}><TBRbook mediaItem={mediaList[i]} /></div>
                )
            }
            return tempList;
        } else if (!isLoading) {
            return (<div className={styles.emptyShelf}><h1>This shelf is currently empty </h1></div>)
        }

        
    }

    // ----------------------------------------------------- RENDER
    return (
        <div className={styles.container}>
            {isLoading ?
                <div className={styles.loader}>
                    <Loader />
                </div>
                :
                <div className={styles.content}  style={{gridTemplateColumns: cssModifier}}>
                    {mediaObjects}
                </div>
            }
        </div>

    );

}