import React from "react";
import styles from "./ProfileDropdownItem.module.css";

export const ProfileDropdownItem = (props) => {

    // props:
    //
    // displayValue - string, human friendly status
    // onClick - action to be performed
    // isSignOut - bool, indicates if item is sign out to make sign out text red

    return (
        <section className={props.isSignOut ? styles.dropdownItemWarning : styles.dropdownItem} onClick={props.onClick}>
            <div className={styles.text}>{props.displayValue}</div>
        </section>
    )
}