import React from "react";
import styles from "./Author.module.css";
import { Link } from "react-router-dom";

export const Author = (props) => {

    // props:
    // 
    // authorsList - array of objects containing author information
    // size - 0 = XS,  1 = S or  2 = L
    // link - indicates if author should link to author page true or false
    // alignRightOverride - bool, if true will not align left in large. null or false does nothing

    // ----------------------------------------------------- STATE AND HOOK
    let sizeOptions = [
        styles.contentXS,
        styles.contentS,
        styles.contentL
    ]

    let andSizeOptions = [
        styles.andXS,
        styles.andS,
        styles.andL
    ]

    const authorDisplay = formatAuthors();
    // ----------------------------------------------------- PRE-RENDER

    function formatAuthors() { 
        // makes author string

        if (props.authorsList === undefined) return;

        let temp = []
        for (let i = 0; i < props.authorsList.length; i++) {
            let authorPath = "/author/" + props.authorsList[i].creatorID;
            let authorDisplayString = props.authorsList[i].first === null ? props.authorsList[i].name : props.authorsList[i].last + ", " + props.authorsList[i].first;
            
            if (props.link) {
                // makes linked author
                temp.push (
                    <Link to={authorPath} className={sizeOptions[props.size]} key={"creator"+props.authorsList[i].id} style={props.alignRightOveride === true ? {textAlign:"left"} : {}}>
                        {authorDisplayString}{ i+1 < props.authorsList.length ? " &" : null}
                    </Link>
                ) 
            } else {
                // makes un-linked author
                temp.push (
                    <div className={sizeOptions[props.size]} key={"creator"+props.authorsList[i].id} style={props.alignRightOveride !== undefined ? {textAlign:"right"} : {textAlign:"left"}}>
                        {authorDisplayString} { i+1 < props.authorsList.length ? " &" : null} 
                    </div>
                ) 
            }
        }  
        // sets to state var
        return temp;
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            {authorDisplay}
        </section>
    )

    
}