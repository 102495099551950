import React, { useEffect, useState } from 'react';
import { getMediaPage, getTotalItems } from '../../api/Api';

import styles from './FavoriteBooks.module.css';
import { PageSelector } from '../PageSelector/PageSelector';
import { TagBook } from "../BookTypes/TagBook/TagBook";
import { Loader } from '../Loader/Loader';

export const FavoriteBooks = () => {

    // ----------------------------------------------------- STATE AND HOOKS

    const [mediaItems, setMediaItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [page, setPage] = useState(1);
    const pageSize= 9;
    const [totalPages, setTotalPages] = useState();

    const [seenDict, setSeenDict] = useState(null);

    let token = localStorage.getItem("token");

    useEffect(() => {
        // first render, gets page total and creates dictonary
        fetchPageTotal();
    }, [])

    useEffect(() => {
        // runs first render and triggers rerender when page changes
        setIsLoading(true);
        if (seenDict && seenDict[page] !== null) {
            // if dictonary set up and data exists
            setMediaItems(seenDict[page]);
            setIsLoading(false);
        } else {
            // otherwise get data
            fetchMedia();
        }
    }, [page])

    // ----------------------------------------------------- PRE-RENDER

    async function fetchMedia() {
        // gets page of favorite books

        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        await getMediaPage(page, pageSize, "favorite", "mediaID.creators.last", true, token)
            .then((foundMedia) =>{
                // saves seen data to dictonary
                if (seenDict !== null) {
                    let temp = seenDict;
                    temp[page] = foundMedia;
                    setSeenDict(temp);
                }

                if (foundMedia === null) setMediaItems(null);
                else setMediaItems(foundMedia); 

                setIsLoading(false);
            })
            .catch((error) => {
                console.log("failed to get favorite media", error);
            })

    }

    async function fetchPageTotal() {
        //gets the total number of books for a given library from back and calculates total number of pages

        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        await getTotalItems("favorite", token)
                .then((itemCount) => {
                    // adds pages to dict
                    let temp = {};
                    for (let i = 1; i < Math.ceil(itemCount / pageSize) + 1; i++) {
                        temp[i] = null;
                    }
                    setSeenDict(temp);
                    // saves page total
                    setTotalPages(Math.ceil(itemCount / pageSize));
                })
                .catch((error) => {
                    console.log("failed to get total number of pages");
                    console.log(error)
                })

    }

    function handleClick(newPage) {
        // handles click to new button page
        setPage(newPage);
    }


    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <h1 className={styles.sectionTitle}>Favorites</h1>
                <div className={styles.pageSelector}>
                    {totalPages > 1 ? <PageSelector totalPages={totalPages} switchPage={handleClick} selectedPage={page} /> : null}
                </div>
            </div>
            <div className={styles.content} id="favorites">
                <div>
                    {isLoading ?
                        <div className={styles.loader}><Loader /></div>
                        :
                        <div className={styles.gridContainer}>
                            {mediaItems.length !== 0 ?
                                mediaItems.map((media) => <div className={styles.book} key={media.mediaID + "favorites"}><TagBook mediaItem={media} /></div>)
                                :
                                <div className={styles.emptyShelf}>
                                    <h1>This shelf is currently empty</h1>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>
    );
}