import React, { useEffect } from "react";
import styles from "./pages.module.css";
import { getMediaByID } from "../api/Api";
import { useParams } from "react-router-dom";
import { useMediaUpdate } from "../contexts/MediaContext";
import { Footer } from "../comonents/Footer/Footer";
import { MediaBanner } from "../comonents/Banners/MediaBanner";

export const Videogame = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    const mediaID = useParams().videogameID;

    const mediaUpdater = useMediaUpdate();

    useEffect(() =>{
        // gets video game on first render
        mediaUpdater({}) // reset media to avoid flash of previous
        fetchVideogame();
    },[])

    // ----------------------------------------------------- PRE-RENDER

    async function fetchVideogame() {
        // gets video game

        await getMediaByID(mediaID, token)
            .then((foundMedia) =>{
                mediaUpdater(foundMedia);
            })
            .catch((error) =>{
                console.log("failed to find videogame", error);
            });
    }
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <MediaBanner />
            </div>
            <Footer />
        </section>
    )
}