import React from "react";
import { Link } from "react-router-dom";

import styles from "./SeriesWorkBook.module.css";
import { Cover } from "../../Cover/Cover";
import { Author } from "../../Author/Author";

import Clamp from "react-multiline-clamp";

export const SeriesWorkBook = (props) => {
    
    // props:
    //
    // mediaItem - object containing book information
    // ----------------------------------------------------- STATE AND HOOKS

    const splitTitle = processTitle();

    let formattedFullDescripton = formatDescription();

    const bookPath = `/${props.mediaItem.type}/` + props.mediaItem.mediaID;
    const seriesNumString = props.mediaItem.seriesNum !== null ? props.mediaItem.seriesNum + ". " : "";

    // ----------------------------------------------------- PRE-RENDER

    function formatDescription() {
        // adds new lines into description

        let formattedDescripton = []
        let descriptionPeices = props.mediaItem.description.split('\n'); //splits description string by newlines
        if (descriptionPeices.length === 1) { //no new lines
            return props.mediaItem.description;
        } else {
            for (let i = 0; i < descriptionPeices.length; i++) {
                if (!descriptionPeices[i]) { //if empty it means there was a blank line
                    formattedDescripton.push(<div key={i} className={styles.spacer}></div>)
                } else {
                    formattedDescripton.push(<div key={i}>{descriptionPeices[i]}</div>) //adds string segment to array
                }
            }
            return (formattedDescripton);
        }
    }

    function processTitle() {
        // splits title into two peices and adds star to second to prevent weird line wrapping
        let words = props.mediaItem.title.split(" ");
        let lastWord = words.pop();

        const firstWords = words.join(" ") + " "
    
        return [firstWords, lastWord];
    }

    // ----------------------------------------------------- RENDER
    return (
        <section className={styles.container}>
            <div className={styles.content}>
                <Cover mediaItem={props.mediaItem} size={window.screen.width > 500 ? "L" : "M"} loc="seriesBook" />
                <div className={styles.bookInfo}>
                    <Link to={bookPath} className={styles.link}>
                        <div >
                            <span className={styles.seriesNum}>{seriesNumString}</span>
                            <span className={styles.firstWordsOfTitle}>{splitTitle[0]}</span>
                            <span className={styles.lastWordOfTitle}>{splitTitle[1]} {props.mediaItem.favorited ? <img key="star" className={styles.starIconFilled} alt="Favorite star" /> : null}</span>
                        </div>
                    </Link>
                    <Author authorsList={props.mediaItem.creators} size={window.screen.width > 500 ? 1 : 0} link={true} />
                    <Clamp lines={4}
                        maxLines={100}
                        withTooltip={false}
                        withToggle={true}
                        showMoreElement={({ toggle }) => (
                            <button className={styles.readMoreOrLessButton}
                                onClick={toggle} >
                                <img className={styles.carrot} src="/assets/images/downCarrot.svg" alt="read more" />
                            </button>
                        )}
                        showLessElement={({ toggle }) => (
                            <button className={styles.readMoreOrLessButton}
                                onClick={toggle} >
                                <img className={styles.carrot} src="/assets/images/upCarrot.svg" alt="read less" />
                            </button>
                        )}>
                        <div className={styles.description}>{formattedFullDescripton} </div>
                    </Clamp>
                </div>
            </div>
        </section>
    )
}