import React, { useEffect, useState } from "react";
import styles from "./MediaBanner.module.css";

import { useMedia } from "../../contexts/MediaContext";

import ScrollToTop from "../../helpers/ScrollToTop";

import { MediaInfoBox } from "../InfoBoxs/MediaInfoBox";

import { EditionBox } from "../EditionBox/EditionBox";
import { TVShowEditionBox } from "../EditionBox/TVShowEditionBox";
import { MovieEditionBox } from "../EditionBox/MovieEditionBox";
import { VideogameEditionBox } from "../EditionBox/VideogameEditionBox";

import { ReviewBox } from "../ReviewBox/ReviewBox";
import { FieldsBox } from "../FieldsBox/FieldsBox";

import { Journal } from "../Journal/Journal";
import { Recommendations } from "../Recommendations/Recommendations";

export const MediaBanner = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    const mediaItem = useMedia();

    let editionBox = pickEditionBox();

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() =>{
        // checks to see if media info loaded yet
        if (Object.keys(mediaItem).length !== 0) {
            setIsLoaded(true);
        }
    },[mediaItem])

    useEffect(() =>{
        // scrolls to top of page on first render
        ScrollToTop();
    },[])
    // ----------------------------------------------------- PRE-RENDER

    function pickEditionBox() {
        // selects which edition box to use
        switch(mediaItem.type) {
            case "book":
                return (<EditionBox mediaItem={mediaItem}/>);
            case "tvshow":
                return (<TVShowEditionBox mediaItem={mediaItem}/>);
            case "movie":
                return (<MovieEditionBox mediaItem={mediaItem}/>);
            case "videogame":
                return (<VideogameEditionBox mediaItem={mediaItem}/>);
        }
    }
    // ----------------------------------------------------- RENDER

    if (!isLoaded) {
        return (
            <div></div>
        )
    } else {
        return (
            <div className={styles.container}>
                <div className={styles.rowOne}>
                    <MediaInfoBox />
                    {editionBox}
                </div>
                {mediaItem.usermediaID ?
                    <div className={styles.userbookInfo}>
                        <div className={styles.rowTwo}>
                            <FieldsBox mediaItem={mediaItem} />
                            <ReviewBox/>
                        </div>

                       <div className={styles.rowThree}>
                            <Recommendations mediaItem={mediaItem} />
                        </div> 

                        <div className={styles.rowFour}>
                            <Journal/>
                        </div>
                    </div>
                    : 
                    null
                }
            </div>
        );
    }
}