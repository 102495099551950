import React, { useEffect, useState } from "react";
import styles from "./NewEntry.module.css";

import Textarea from 'rc-textarea';


export const NewEntry = (props) => {

    // props:
    //
    // mediaItem - object containing all book information
    // setNewEntry - state setter to invoke post entry
    // oldEntry - optional, if editing an old entry instead of starting from sctatch
    // setIsAdding - state setter for cancel adding

    // ----------------------------------------------------- STATE AND HOOK

    const [pageTracker, setPageTracker] = useState(); //update current page
    const [titleTracker, setTitleTracker] = useState("");
    const [bodyTracker, setBodyTracker] = useState("");
    const [readingTracker, setReadingTracker] = useState(0);

    useEffect( () => { 
        // sets page tracker to 0 if progress is null
        if (props.mediaItem && props.mediaItem.progress) {
            setPageTracker(props.mediaItem.progress)
        } else { 
            setPageTracker(0)
        }

        // finds reading numer
        if (props.mediaItem && props.mediaItem.currentReading !== null) {
            for (let i = 0; i < props.mediaItem.readings.length; i++) {
                if (props.mediaItem.readings[i].readingID === props.mediaItem.currentReading.readingID) {
                    // finds reading in book's list of readings
                    setReadingTracker(i+1);
                }
            }
        }
    },[props.mediaItem])

    // ----------------------------------------------------- PRE-RENDER

    const changePage = (event) => {
        // catches changes to page number
        setPageTracker(event.target.value);
    }

    const changeTitle = (event) => {
        // catches changes to title input
        setTitleTracker(event.target.value)
    }

    const changeBody = (event) => {
        // catches changes to body input
        setBodyTracker(event.target.value);
    }

    const changeReading = (event) => {
        // catches changes to reading input
        setReadingTracker(event.target.value);
    }

    const submitEntry = () => {
        // sets new entry to state var of journal

        let entryPostModel = {}

        if (readingTracker === 0) {
            // no reading, thus set to null

           entryPostModel = {
                title: titleTracker,
                body : bodyTracker,
                page : pageTracker,
                readingID : null
            }

        } else {
            // attach to reading
            console.log("trying to attach reading",props.mediaItem.readings[readingTracker - 1].readingID)

            entryPostModel = {
                title: titleTracker,
                body : bodyTracker,
                page : pageTracker,
                readingID : props.mediaItem.readings[readingTracker - 1].readingID
            }
        }
        props.setNewEntry(entryPostModel);
    }

    // ----------------------------------------------------- RENDER
    return (
        <section className={styles.container}>
            <div className={styles.inputGroup}>
                <div className={styles.inputTitle}>Title</div>
                <input className={styles.input} key="title input"
                    type="text"
                    placeholder="Title of new journal entry"
                    value={titleTracker}
                    onChange={changeTitle} />
            </div>

            <div className={styles.pageAndReading}>
                <div className={styles.inputGroup}>
                    <div className={styles.inputTitle}>Progress</div>
                    <input className={styles.input} key="page or epsiode input"
                        type="number"
                        value={pageTracker}
                        onChange={changePage}
                        max={props.mediaItem.numPages} />
                </div>
                {
                    props.mediaItem.readings !== null ?
                        <div className={styles.inputGroup}>
                            <div className={styles.inputTitle}>Reading</div>
                            <input className={styles.input} key="reading input"
                                type="number"
                                placeholder="Reading number"
                                value={readingTracker}
                                onChange={changeReading}
                                max={props.mediaItem.readings.length}
                                min={0} />
                        </div>
                        :
                        <></>
                }
            </div>
            <div className={styles.inputGroup}>
                <div className={styles.inputTitle}>Body</div>
                <Textarea className={styles.inputBody} key="body input"
                    autoSize={ {minRows: 10} }
                    type="text"
                    placeholder="Body of new journal entry" 
                    value={bodyTracker}
                    onChange={changeBody}/>
            </div>

            <div className={styles.saveAndCancel}>
                <button type="submit" className={styles.cancelButton} onClick={ () => props.setIsAdding(false) }>Cancel</button>
                <button type="submit" className={styles.submitButton} onClick={ () => submitEntry() }>Submit</button>
            </div>
        </section>
    )
}