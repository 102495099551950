import React from "react";

import styles from "./EditionBox.module.css";
import { useMedia } from "../../contexts/MediaContext";

export const EditionBox = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    const mediaItem = useMedia();

    // ----------------------------------------------------- PRE-RENDER
    
    // formats date
    let date = "";
    if (mediaItem && mediaItem.release) {  
        const temp = new Date(mediaItem.release)
        date = new Date(temp.getTime() - temp.getTimezoneOffset() * -60000).toLocaleString('en-us', { day: 'numeric', month: 'long', year: 'numeric' });
    }

    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.container}>
            <h4 className={styles.header}>ISBN:</h4>
            <div className={styles.value}>{mediaItem.isbn}</div>

            <h4 className={styles.header}>Pages:</h4>
            <div className={styles.value}>{mediaItem.numPages}</div>

            <h4 className={styles.header}>First Published:</h4>
            <div className={styles.value}>{date}</div>

            {mediaItem.genres?.length > 0 ?
                <div>
                    <h4 className={styles.header}>Genre:</h4>
                    <div className={styles.genreList}>{mediaItem.genres.map((g) => <div className={styles.genre} key={g.genreID}>{g.genreNAME}</div>)}</div>
                </div>
                :
                null
            }
        </div>
    );
}