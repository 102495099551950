import React, { useEffect, useState } from "react";

import styles from "./StarRating.module.css";

import { patchUsermedia } from "../../api/Api";
import { useMedia } from "../../contexts/MediaContext";

export const StarRating = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    const mediaItem = useMedia();

    let token = localStorage.getItem("token");
    let location = window.location.href

    const [activeStarFragments, setActiveStarFragments] = useState(0);
    const [hover, setHover] = useState(0);

    useEffect(() => { 
        // sets inital rating once props load
        setActiveStarFragments(mediaItem.rating)
        setHover(mediaItem.rating)
    }, [mediaItem.rating]);

    // ----------------------------------------------------- LOGIC

    function determineIndexState(index) {
        const stylesArr = index <= hover ?
            [styles.r_active, styles.l_active] :
            [styles.r_inactive, styles.l_inactive];
        return stylesArr[index % 2];
    }

    function handleChanges(index) {
        let sendVal = index // val to be sent to db, 0 if same star was clicked twice in a row
        // thus clearing the rating
        if (index === activeStarFragments) { // sets sendVal to 0 if ^^
            sendVal = 0;
            setActiveStarFragments(0);
        }
        setActiveStarFragments(sendVal);
        setHover(sendVal);

        const ratingUpdateObj = { // object to be sent to db
            rating: sendVal
        }
        sendChanges(ratingUpdateObj);
    }

    async function sendChanges(obj) { 
        // makes changes to db

        await patchUsermedia(mediaItem.usermediaID, obj, token)
            .catch((error) => {
                console.log(error, "failed to update rating");
            });

    }

    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.container}>
            {[...Array(10)].map((star, index) => {
                index += 1;
                return (
                    <button type="button"
                        key={index}
                        className={styles.button}
                        onClick={() => handleChanges(index)}
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(activeStarFragments)}>
                        <img className={determineIndexState(index)} alt="star" />
                    </button>
                );
            })}
        </div>
    );

}