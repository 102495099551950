import React, { useEffect, useState } from "react";
import styles from "./Recommendations.module.css";

import { RecommendationBook } from "../BookTypes/RecommendationBook/RecommendationBook";
import { useMedia } from "../../contexts/MediaContext";
import { NewRecommendation } from "./NewRecommendation";
import { Loader } from "../Loader/Loader";

import { postRecommendation } from "../../api/Api";

export const Recommendations = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    const mediaItem = useMedia();

    const [isAdding, setIsAdding] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const recObjects = makeRecommendationBooks();
    const [newRecommendation, setNewRecommendation] = useState(null);

    useEffect(() => {
        // posts recommendation to back
        if (newRecommendation !== null ) sendNewRec();
    },[newRecommendation])

    // ----------------------------------------------------- PRE-RENDER

    function makeRecommendationBooks() {
        // creates book recommendation objects
        if (mediaItem.recommendations === null) return;
        let tempArray = [];

        // sorts recs by author of recommended media
        let sortedRecs = mediaItem.recommendations.sort(function (a, b) {
            if (a.mediaB.creators === undefined || b.mediaB.creators === undefined
                || a.mediaB.creators.length === 0 || b.mediaB.creators.length === 0
            ) {
                // no creator
                return -1;
            } else if (a.mediaB.creators[0].last > b.mediaB.creators[0].last) {
                return 1;
            } else if (a.mediaB.creators[0].last < b.mediaB.creators[0].last) {
                return - 1;
            }
            return 0;
        })

        for (let i = 0; i < mediaItem.recommendations.length; i++) {
            tempArray.push(
                <div key={sortedRecs[i].recommendationID}><RecommendationBook recItem={sortedRecs[i]} mediaItem={mediaItem} /></div>
            )
        }

        return tempArray;
    }

    async function sendNewRec() {
        // sends recommendation(s) to back
        setIsAdding(false);
        setIsLoading(true);


        let recPostObject = {
            mediaA: newRecommendation.mediaA,
            mediaB: newRecommendation.mediaB,
            note: newRecommendation.note
        }

        if (newRecommendation.reciprical === true) {
            // posts reciprical recommendation
            let recPostObjecRecip = {
                mediaA: newRecommendation.mediaB,
                mediaB: newRecommendation.mediaA,
                note: newRecommendation.note
            }

            await postRecommendation(recPostObjecRecip, token)
                .catch((error) => {
                    console.log("failed to post reciprical recommendation")
                    console.log(error);
                    setIsLoading(false);
                })

        }

        // posts primary recommendation
        await postRecommendation(recPostObject, token)
            .then((newRec) => {
                // resets states
                setNewRecommendation(null);
                setIsAdding(false);

                // creates rec obejects
                let temp = mediaItem.recommendations
                temp.push(newRec)

                makeRecommendationBooks(temp);
            })
            .catch((error) => {
                console.log("failed to post recommendation")
                console.log(error);
                setIsLoading(false);
            })
    }


    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.header}>
                <span><h3 className={styles.headerTitle}>{window.screen.width > 500 ? "Recommendations" : "Recs"}</h3></span>
                <button className={styles.plusButton} onClick={() => setIsAdding(!isAdding)}><img src="/assets/images/plusIcon.svg" alt="add icon" className={styles.icon} /></button>
            </div>
            {
                isAdding ? 
                    <NewRecommendation setIsAdding={setIsAdding} setNewRecommendation={setNewRecommendation}/>
                    :
                    null
            }
            {
                isLoading ?
                    <div className={styles.loader}><Loader /></div>
                    :
                    null
            }
            <div className={styles.content}>
                {recObjects}
            </div>
        </section>
    )
}