import React from "react";
import styles from "./ProfileDropdownButton.module.css";

export const ProfileDropdownButton = (props) => {

    // props:
    // 
    // isOpen - state var if dropdown is open

    // ----------------------------------------------------- STATES AND HOOKS
    // ----------------------------------------------------- PRE-RENDER
    // ----------------------------------------------------- RENDER
    
    return (
        <section className={styles.container} onClick={props.toggle}>
            <img src="/assets/images/profile.svg" className={styles.profileIcon} />
        </section>
    );
}