import React, { useEffect } from "react";
import styles from "./pages.module.css";
import { useParams } from "react-router-dom";
import { getMediaByID } from "../api/Api";
import { useMediaUpdate } from "../contexts/MediaContext";
import { Footer } from "../comonents/Footer/Footer";
import { MediaBanner } from "../comonents/Banners/MediaBanner";

export const Movie = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    let mediaID = useParams().movieID;

    const mediaUpdater = useMediaUpdate();

    useEffect(() => {
        // get movie on firs render
        mediaUpdater({}) // reset media to avoid flash of previous
        fetchMovie();
    },[])
    // ----------------------------------------------------- PRE-RENDER

    async function fetchMovie() {
        // gets media from back
        await getMediaByID(mediaID, token)
            .then((foundMedia) => {
                mediaUpdater(foundMedia);
            })
            .catch((error) =>{
                console.log("failed to fetch media ", error);   
            })
    }
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.content}> 
                <MediaBanner />
            </div>
            <Footer />
        </section>
    )
}