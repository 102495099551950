import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "./TBRorWatch.module.css";

import { getTotalItems } from "../../api/Api.js"

import { HomeTBR } from "../TBR/HomeTBR";
import { WatchList } from "../WatchList/WatchList";

import { Dropdown } from '../Dropdown/Dropdown';
import { DropdownItem } from '../Dropdown/DropdownItem';

import { PageSelector } from '../PageSelector/PageSelector';
import { RecentlyAdded } from "../RecentlyAdded/RecentlyAdded.js";

export const TBRorWatch = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");

    let [selectedLibrary, setSelectedLibrary] = useState(getSelectedLibrary()); //state var to drive re-render

    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState();

    useEffect(() => { // gets total number of watch pages on first render
        if (selectedLibrary === "watch") fetchPageTotal();
    },[selectedLibrary])

    // ----------------------------------------------------- PRE-RENDER

    function getSelectedLibrary() {
        // gets selection from localStorage
        const libraryString = localStorage.getItem("homepageLibraySelection");
        const library = JSON.parse(libraryString);

        if (library === null) { // default
            return "TBR";
        } else {
            return library;
        }
    }

    const allOptions = [
        <DropdownItem key="TBR" displayValue="Up Next?" onClick={() => saveSelectedLibrary("TBR")} />,
        <DropdownItem key="watch" displayValue="Watch List" onClick={() => saveSelectedLibrary("watch")} />,
        <DropdownItem key="recent" displayValue="Recent" onClick={() => saveSelectedLibrary("recent")}/>
    ];

    const statusDict = { // mappings of backend status values to display values
        "TBR": "Up Next?",
        "watch": "Watch List",
        "recent": "Recent"
    };

    function saveSelectedLibrary(library) {
        // saves selection to localStorage and updates state
        localStorage.setItem("homepageLibraySelection", JSON.stringify(library))
        setSelectedLibrary(library);
    }

    function handlePageClick(newLibrary) {
        // handles click to new button page
        setPage(newLibrary);
    }

    async function fetchPageTotal() {
        //gets the total number of books for a watch library from back and calculates total number of pages

        await getTotalItems("watch", token)
            .then((itemCount) => {
                setTotalPages(Math.ceil(itemCount / 3)); // 3 = page size
            })
            .catch((error) => {
                console.log("failed to get total number of pages for watch list");
                console.log(error)
            })
    }


    // ----------------------------------------------------- RENDER
    switch (selectedLibrary) {
        case "watch":
            return (
                <div className={styles.container}>
                        <div className={styles.header}>
                        <div className={styles.dropdown}><Dropdown buttonText={statusDict[selectedLibrary]} content={allOptions} /></div> 
                            <div className={styles.pageSelector}>
                                {totalPages > 1 ? <PageSelector totalPages={totalPages} switchPage={handlePageClick} selectedPage={page} /> : null}
                            </div>
                        </div>
                        <WatchList page={page} />
                </div>
            )

        case "TBR":
            return (
                <div className={styles.container}>
                    <div className={styles.header}>
                    <div className={styles.dropdown}><Dropdown buttonText={statusDict[selectedLibrary]} content={allOptions} /></div> 
                        <Link to={"/library?page=1&library=TBR"} className={styles.link} ><div className={styles.pageSelector}>See All</div></Link>
                    </div>
                    <HomeTBR />
                </div>
            )
        case "recent":
            return (
                <div className={styles.container}>
                    <div className={styles.header}>
                    <div className={styles.dropdown}><Dropdown buttonText={statusDict[selectedLibrary]} content={allOptions} /></div> 
                    </div>
                    <RecentlyAdded  />
                </div>
            )
    }
    // return (
    //     <div className={styles.container}>
    //         {selectedLibrary == "watch" ?
    //             (
    //                 <div>
    //                     <div className={styles.header}>
    //                         <div className={styles.dropdown}><Dropdown buttonText={statusDict[selectedLibrary]} content={allOptions} /></div>
    //                         <div className={styles.pageSelector}>
    //                             {totalPages > 1 ? <PageSelector totalPages={totalPages} switchPage={handlePageClick} selectedPage={page} /> : null}
    //                         </div>
    //                     </div>
    //                     <WatchList page={page}/>
    //                 </div>
    //             ) :
    //             (
    //                 <div>
    //                     <div className={styles.header}>
    //                         <div className={styles.dropdown}><Dropdown buttonText={statusDict[selectedLibrary]} content={allOptions} /></div>
    //                         <Link to={"/library?page=1&library=TBR"} className={styles.link} ><div className={styles.pageSelector}>See All</div></Link>
    //                     </div>
    //                     <HomeTBR />
    //                 </div>
    //             )
    //         }
    //     </div>
    // )

}