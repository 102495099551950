import React from "react";
import styles from "./EditionBox.module.css";

import { useMedia } from "../../contexts/MediaContext";

export const AlbumEditionBox = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    const mediaItem = useMedia();

    // ----------------------------------------------------- PRE-RENDER

    // formats date
    let date = "";
    if (mediaItem && mediaItem.release) {
        const temp = new Date(mediaItem.release)
        date = new Date(temp.getTime() - temp.getTimezoneOffset() * -60000).toLocaleString('en-us', { day: 'numeric', month: 'long', year: 'numeric' });
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <h4 className={styles.header}>Songs</h4>
            <div className={styles.value}>{mediaItem.numPages}</div>

            <h4 className={styles.header}>Release Date</h4>
            <div className={styles.value}>{date}</div>
        </section>
    )
}