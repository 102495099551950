import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import styles from "./LibraryList.module.css";

import { getMediaPage, getMediaTypePage, getTotalItems, getTotalTypeItems } from '../../api/Api';

import { Dropdown } from '../Dropdown/Dropdown';
import { CompactBook } from '../BookTypes/CompactBook/CompactBook';
import { DropdownItem } from '../Dropdown/DropdownItem';
import { PageSelector } from '../PageSelector/PageSelector';
import { useMediaUpdate } from '../../contexts/MediaContext';
import { Loader } from '../Loader/Loader';
import { SmallDropdown } from '../SmallDropdown/SmallDropdown';
import { SmallDropdownItem } from '../SmallDropdown/SmallDropdownItem';
import ScrollToTop from '../../helpers/ScrollToTop';


export const LibraryList = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    const [searchParams, setSearchParams] = useSearchParams({ "page": "1", "library": "read" }); // saves page to url
    const page = searchParams.get("page"); // page value

    let pageSize = 10;
    let token = localStorage.getItem("token"); 
    let types = [
        "Albums",
        "Books",
        "Movies",
        "TV Shows"
    ]

    const mediaUpdater = useMediaUpdate();

    const [mediaItems, setMediaItems] = useState([]);

    // filters
    const [selectedLibrary, setSelectedLibrary] = useState(searchParams.get("library"));
    const [selectedType, setSelectedType] = useState(null);

    const [totalPages, setTotalPages] = useState();

    const [isLoading, setIsLoading] = useState(true);
    const [finishedFirstRender, setFinishedFirstRender] = useState(false);

    // const mediaObjects = makeMediaObjects();
    const typeObjects = makeTypeObjects();

    const [seenDict, setSeenDict] = useState(null);

    //sorting
    const [asc, setAsc] = useState(getDefaultSortAsc());
    const [sortBy, setSortBy] = useState(getDefaultSort())


    useEffect(() => {
        mediaUpdater({}); // sets saved book to empty to avoid flash of last clicked book

        if (selectedType !== null) {
            fetchMediaByType();
            fetchTypePageTotal();
        } else {
            fetchMedia();
            fetchPageTotal();
        }
        setFinishedFirstRender(true);
    }, []);

    useEffect(() =>{
        // triggers rerender to make media objects when date comes back
    },[mediaItems])

    useEffect(() => {
        // triggers rerender when page changes
        if (finishedFirstRender) {
            if (seenDict[page] === null) {
                // calls backend for page not looked at yet

                console.log("calling back")
                if (selectedType !== null) {
                    // YES selected type
                    fetchMediaByType();
                } else {
                    // NO selected type
                    fetchMedia()
                }
            } else {
                // gets data from seen dictionary 
                console.log("NOT calling back")
                setMediaItems(seenDict[page]);
            }
        }
        ScrollToTop();

    }, [page])

    useEffect(() => {
        // triggers rerender when selected library or selected type changes
        if (finishedFirstRender) {
            setTotalPages(0);

            if (selectedType !== null) {
                fetchTypePageTotal();
                fetchMediaByType();
                
            } else {
                fetchPageTotal();
                fetchMedia()   
            }
        }
    }, [selectedLibrary, selectedType]);

    useEffect(() => {
        // gets book page when sort filter changes

        if (finishedFirstRender) {
            // resets seen dictonary
            let temp = {}
            for (let i = 1; i < totalPages + 1; i++) {
                temp[i] = null;
            }
            setSeenDict(temp);

            // gets media
            if (selectedType !== null) {
                fetchMediaByType();
            } else {
                fetchMedia();
            }
        }
    }, [sortBy, asc]);

    // ----------------------------------------------------- PRE- RENDER

    const statusDict = {
        // mappings of backend status values to display values
        "library": "Library",
        "read": "Read",
        "DNF": "DNF",
        "TBR": "TBR",
        "watch": "Watching",
        "recent": "Recent"
    }

    const sortDict = {
        // mappings of backend sort values to display values
        "mediaID.creators.last": ["Creator: Z-A", "Creator: A-Z"],
        "mediaID.title": ["Title: Z-A", "Title: A-Z"],
        "usermediaID": ["Most Recent", "Least Recent"],
        // "rating" : ["Highest Rating", "Lowest Rating"]
    }

    const allOptions = [ //avaliable libraries
        <DropdownItem key="library" displayValue="Library" onClick={() => handleLibrayChange("library")}></DropdownItem>,
        // <DropdownItem key="recent" displayValue="Recent" onClick={() => handleLibrayChange("recent")}></DropdownItem>,
        <DropdownItem key="watch" displayValue="Watching" onClick={() => handleLibrayChange("watch")}></DropdownItem>,
        <DropdownItem key="TBR" displayValue="TBR" onClick={() => handleLibrayChange("TBR")}></DropdownItem>,
        <DropdownItem key="read" displayValue="Read" onClick={() => handleLibrayChange("read")}></DropdownItem>,
        <DropdownItem key="DNF" displayValue="DNF" onClick={() => handleLibrayChange("DNF")}></DropdownItem>,
    ];

    const sortOptions = [
        <SmallDropdownItem key="authorAZ" displayValue="Creator: A-Z" onClick={() => handleSortChange("mediaID.creators.last", 1)} />,
        <SmallDropdownItem key="authorZA" displayValue="Creator: Z-A" onClick={() => handleSortChange("mediaID.creators.last", 0)} />,
        <SmallDropdownItem key="titleAZ" displayValue="Title: A-Z" onClick={() => handleSortChange("mediaID.title", 1)} />,
        <SmallDropdownItem key="titleZA" displayValue="Title: Z-A" onClick={() => handleSortChange("mediaID.title", 0)} />,
        <SmallDropdownItem key="mostrecent" displayValue="Most Recent" onClick={() => handleSortChange("usermediaID", 0)} />,
        <SmallDropdownItem key="leastrecent" displayValue="Least Recent" onClick={() => handleSortChange("usermediaID", 1)} />,
        // <SmallDropdownItem key="highestrating" displayValue="Highest Rating" onClick = {() => handleSortChange("rating", 0)}/>,
        // <SmallDropdownItem key="lowestrating" displayValue="Lowest Rating" onClick = {() => handleSortChange("rating", 1)}/>
    ];

    const typeDict = {
        // mappings of display values to backend values
        "TV Shows" : "tvshow",
        "Books" : "book",
        "Albums" : "album",
        "Movies" : "movie"
    }


    // ----------------------------------------------------- LOGIC

    async function fetchMedia() {
        // gets books from database
        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        setIsLoading(true);

        await getMediaPage(page, pageSize, selectedLibrary, sortBy, asc, token)
            .then((foundMedia) => {
                if (seenDict !== null) {
                    // saves data to seen dict
                    let temp = seenDict;
                    temp[page] = foundMedia;
                    setSeenDict(temp);
                }
                setIsLoading(false);
                setMediaItems(foundMedia);
            })
            .catch((error) => {
                console.log("failed to get media page", error)
            });
    }

    async function fetchMediaByType() {
        // gets media filtered by type

        setIsLoading(true);

        await getMediaTypePage(page, pageSize, selectedLibrary, sortBy, asc, typeDict[selectedType], token)
            .then((foundMedia) => {
                if (page === searchParams.get("page")) { // prevents a call from being slow and overwritting new page data
                    if (seenDict !== null) {
                        // saves data to seen dict
                        let temp = seenDict;
                        temp[page] = foundMedia;
                        setSeenDict(temp);
                    }
                    setIsLoading(false);
                    setMediaItems(foundMedia);
                }
            })
            .catch((error) => {
                console.log("failed to get media page", error)
            });
    }

    async function fetchPageTotal() {
        // gets the total number of books for a given library from back and calculates total number of pages

        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        await getTotalItems(selectedLibrary, token)
            .then((itemCount) => {
                let totalPages = Math.ceil(itemCount / pageSize)
                setTotalPages(totalPages)

                // sets up seen items dictonary with a slot for each page
                let temp = {}
                for (let i = 1; i < totalPages + 1; i++) {
                    temp[i] = null;
                }
                setSeenDict(temp);

                if (page > totalPages) {
                    // re-sets page back to one if new total pages is less than currently selected
                    setSearchParams(prev => {
                        prev.set("page", 1)
                        return prev
                    }, { replace: true });
                }
            })
            .catch((error) => {
                console.log("failed to get total number of pages");
                console.log(error)
            })

    }

    async function fetchTypePageTotal() {
        // gets total pages for a given library and tyoe

        await getTotalTypeItems(selectedLibrary, typeDict[selectedType], token)
        .then((itemCount) => {
            let totalPages = Math.ceil(itemCount / pageSize);
            setTotalPages(totalPages);

            // sets up seen items dictonary with a slot for each page
            let temp = {}
            for (let i = 1; i < totalPages + 1; i++) {
                temp[i] = null;
            }
            setSeenDict(temp);

            if (page > totalPages) {
                // re-sets page back to one if new total pages is less than currently selected
                setSearchParams(prev => {
                    prev.set("page", 1)
                    return prev
                }, { replace: true });
            }
        })
        .catch((error) => {
            console.log("failed to get total number of pages");
            console.log(error)
        })

    }

    function makeMediaObjects() {
        // makes list of media objects

            let tempList = mediaItems.map((media) =>
                <CompactBook mediaItem={media} key={media.mediaID} />);
            return (tempList);
         
    }

    function makeTypeObjects() {
        // creates objects that allow selecting of type to filter by

        let temp = [];
        for (let i = 0; i < types.length; i++) {
            temp.push(
                <button className={selectedType === types[i] ? styles.activeType : styles.inactiveType}
                    key={"typeselector"+i}
                    onClick={ () => handleTypeClick(i) }>
                        {types[i]}
                    </button>
            )
        }
        return temp;
    }

    function getDefaultSort() {
        // gets default sorting from local storage
        const sortString = localStorage.getItem("defaultSort");
        const defaultSort = JSON.parse(sortString);

        if (defaultSort === null) {
            return "mediaID.creators.last";
        } else {
            return defaultSort.sortBy;
        }
    }

    function getDefaultSortAsc() {
        // gets default sorting asc or desc from local storage
        const sortString = localStorage.getItem("defaultSort");
        const defaultSort = JSON.parse(sortString);

        if (defaultSort === null) {
            return 1;
        } else {
            return defaultSort.asc;
        }
    }

    function handleClick(newPage) {
        // handles click to new page buttons
        setSearchParams(prev => {
            prev.set("page", newPage)
            return prev
        }, { replace: true });

    }

    function incPage() {
        // increases page by one as caused by next button on bottom of page
        let newPage = Number(page) + 1;
        if (newPage > totalPages) {
            return;
        }
        setSearchParams(prev => {
            prev.set("page", newPage)
            return prev
        }, { replace: true });

        window.scrollTo(0, 0); // scrolls window to top
    }

    function decPage() {
        // decreases page by one as caused by back button on bottom of page
        let newPage = Number(page) - 1;
        if (newPage < 1) {
            return;
        }
        setSearchParams(prev => {
            prev.set("page", newPage)
            return prev
        }, { replace: true });

        window.scrollTo(0, 0)
    }

    function handleLibrayChange(newLibrary) {
        // changes library selection
        setSearchParams(prev => {
            prev.set("library", newLibrary)
            return prev
        }, { replace: true });

        // resets page to 1
        setSearchParams(prev => {
            prev.set("page", 1)
            return prev
        }, { replace: true });

        setSelectedLibrary(newLibrary);
    }

    function handleSortChange(newSortBy, newAsc) {
        // changes sort filter

        localStorage.setItem("defaultSort", JSON.stringify(
            {
                "sortBy": newSortBy,
                "asc": newAsc
            }
        ));
        setSortBy(newSortBy);
        setAsc(newAsc);
    }

    function handleTypeClick(typeIndex) {
        // sets selected type 
        if (types[typeIndex] === selectedType) {
            // unselects type if clicked again
            setSelectedType(null);
        } else {
            setSelectedType(types[typeIndex]);
        }
    }



    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.container}>
            <div className={styles.header}>
                <div className={styles.librarySelector}>
                    <Dropdown buttonText={statusDict[selectedLibrary]} content={allOptions} fontSize={window.screen.width < 500 ? 32 : 48} />
                </div>
                <div className={styles.pageSelector}>
                    <PageSelector totalPages={totalPages} switchPage={handleClick} selectedPage={page} />
                </div>
            </div>
            {selectedLibrary === "library" || selectedLibrary === "read" || selectedLibrary === "TBR" || selectedLibrary === "DNF" ?
                <div className={styles.sortBySelector}>
                    <SmallDropdown buttonText={`Sort by ${sortDict[sortBy][asc]}`} content={sortOptions} />
                </div>
                :
                null}
            <div className={styles.typeSelectors}>
                {typeObjects}
            </div>

            {isLoading ?
                <div className={styles.loader}><Loader /></div>
                :
                <div className={styles.content}>
                    {
                        mediaItems.length === 0 ?
                            <div className={styles.emptyShelf}>
                                <h1>This shelf is currently empty</h1>
                            </div>
                            :
                            mediaItems.map((media) =>
                                <CompactBook mediaItem={media} key={media.mediaID} />)
                    }
                </div>
            }

            {totalPages > 1 ?
                <div className={styles.buttonContainer}>
                    {Number(page) !== 1 ?
                        <button className={styles.carrotBackButton} onClick={decPage} > <img className={styles.carrot} src="/assets/images/backIcon.svg" /> </button>
                        :
                        <div className={styles.carrotNextButton} />
                    }
                    {Number(page) !== totalPages ?
                        <button className={styles.carrotNextButton} onClick={incPage} > <img className={styles.carrot} src="/assets/images/nextIcon.svg" /> </button>
                        :
                        <div className={styles.carrotBackButton} />
                    }
                </div>
                :
                null
            }
        </div>
    );

}