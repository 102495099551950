import React, { useState } from "react";
import styles from "./ProgressUpdater.module.css";

import ProgressBar from "@ramonak/react-progress-bar";
import { patchUsermedia } from "../../api/Api";

export const TVShowProgressUpdater = (props) => {
    // props:
    // 
    // tvshowItem - object containing tvshow information
    // tvshowUpdater - function to update tvshowItem

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    let progressValue = Math.round((props.tvshowItem.progress / props.tvshowItem.submedia.length) * 100); // recalculates when renderProgress changes

    const [episodeIterator, setEpisodeIterator] = useState(props.tvshowItem.progress);
    const progressCheck = (progressValue > 20); // Where to place progress bar lable

    const [isSaving, setIsSaving] = useState(false);

    // ----------------------------------------------------- PRE-RENDER
    async function saveChanges() {
        // sends progress to back
        setIsSaving(true);
        await patchUsermedia(props.tvshowItem.usermediaID, {progress : episodeIterator}, token)
            .then((updatedTVshow) => {
                setIsSaving(false);
                props.tvshowUpdater(updatedTVshow);
            })
            .catch((error) => {
                console.log(error, "failed to update tvshow progress")
                setIsSaving(false);
            });
    }
    
    const iteriateEpisode = (event) => {
        // catches changes to episode number

        if (event.target.value < 0) {
            // does not allow iterator to be set less than 0
            return;
        } else if (event.target.value > props.tvshowItem.submedia.length) {
            // does not allow iterator to be set more than episode count
            return;
        }

        // changes iterator
        setEpisodeIterator(event.target.value);
    }

    const handleKeyDown = (event) => {
        // catches enter press and triggers saving
        if (event.keyCode === 13) {
            saveChanges();
        }
    }
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            {progressCheck ? (
                <ProgressBar
                    className={styles.progressBar}
                    completed={progressValue}
                    bgColor="#7184AE"
                    labelColor="#fff"
                    labelAlignment="right"
                />
            ) : (
                <ProgressBar
                    className={styles.progressBar}
                    completed={progressValue}
                    bgColor="#7184AE"
                    labelColor="#7184AE"
                    labelAlignment="outside"
                />
            )}
            <div className={styles.pageItiratorAndSave}>
                <input name="Episode"
                    value={episodeIterator}
                    onChange={iteriateEpisode}
                    placeholder={props.tvshowItem.progress}
                    inputMode="numeric"
                    type="number"
                    max={props.tvshowItem.submedia.length}
                    className={styles.inputBox}
                    onKeyDown={handleKeyDown}
                />
                <button className={styles.saveButton}
                    type="submit"
                    onClick={isSaving ? null : saveChanges}
                >{isSaving ? "Saving..." : "Save"}
                </button>
            </div>
        </section>
    )
}