import React, { useEffect, useState } from 'react';
import styles from "./CurrentlyReading.module.css";
import { getMediaByLibrary } from '../../api/Api';
import { Loader } from '../Loader/Loader';

import { CurrentlyReadingBook } from "../BookTypes/CurrentlyReadingBook/CurrentlyReadingBook";

export const CurrentlyReading = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");

    const [mediaItems, setMediaItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        //runs on first render
        fetchMedia();
    }, []);

    useEffect(() => {
        // triggers rerender when media arrives
    }, [mediaItems])


    // ----------------------------------------------------- LOGIC

    function reloadBooks() {
        // function for children to refresh page
        fetchMedia();
    }

    async function fetchMedia() {
        // gets currently reading books
        // determining access level
        let tokenPeices = token.split(".");
        let idAndRole = atob(tokenPeices[1]);
        let role = idAndRole.split(",")[1];

        await getMediaByLibrary("currentlyReading", token)
            .then((foundMedia) => {
                try {
                    // trys to sorts media by marked as reading order
                    let sortedMedia = foundMedia.sort(function (a, b) {
                        if (a?.currentReading.readingID > b?.currentReading.readingID) {
                            return -1;
                        }
                        else return 1;
                    });
                    setMediaItems(sortedMedia);
                } catch {
                    setMediaItems(foundMedia);
                }
                setIsLoading(false);
            })
            .catch((error) => {
                console.log("failed to fetch media", error);
            });

    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <h1 className={styles.sectionTitle}>Current</h1>
            <div>
                { isLoading ?
                    <></>
                    :
                    <div className={styles.content}>
                        {mediaItems.length !== 0 ?
                            mediaItems.map((media) => <CurrentlyReadingBook mediaItem={media} reload={reloadBooks} key={media.type + media.mediaID} />)
                            :
                            <div className={styles.emptyShelf}>
                                <h1>This shelf is currently empty</h1>
                            </div>
                        }
                    </div>
                }
            </div>
        </section>

    );

}