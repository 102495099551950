import React, { useEffect, useState } from "react";
import styles from "./AlbumBanner.module.css";

import { useMedia } from "../../contexts/MediaContext";
import ScrollToTop from "../../helpers/ScrollToTop";

import { MediaInfoBox } from "../InfoBoxs/MediaInfoBox";
import { AlbumEditionBox } from "../EditionBox/AlbumEditionBox";
import { ReviewBox } from "../ReviewBox/ReviewBox";
import { FieldsBox } from "../FieldsBox/FieldsBox";
import { Journal } from "../Journal/Journal";
import { Recommendations } from "../Recommendations/Recommendations";
import { AlbumInfoBox } from "../InfoBoxs/AlbumInfoBox";
import { SongList } from "../SongList/SongList";

export const AlbumBanner = (props) => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    let mediaItem = useMedia();

    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        // checks to see if book data has loaded yet
        if (Object.keys(mediaItem).length !== 0) {
            setIsLoaded(true);
        }
    }, [mediaItem]);

    useEffect(() => {
        // scrolls to top on firs render
        ScrollToTop();
    }, [])

    // ----------------------------------------------------- PRE-RENDER
    // ----------------------------------------------------- RENDER

    if (!isLoaded) {
        return (
            <></>
        )
    } else {
        return (
            <section className={styles.container}>
                <div className={styles.rowOne}>
                    <AlbumInfoBox />
                    <SongList />
                    <AlbumEditionBox />
                </div>
                {mediaItem.usermediaID ?
                    <div className={styles.userbookInfo}>
                       <div className={styles.rowTwo}>
                            <FieldsBox mediaItem={mediaItem} />
                            <ReviewBox/>
                        </div>

                       <div className={styles.rowThree}>
                            <Recommendations mediaItem={mediaItem} />
                        </div> 

                        <div className={styles.rowFour}>
                            <Journal/>
                        </div>
                    </div>
                    :
                    null
                }
            </section>
        )
    }

}