import React from "react";
import styles from "./EditionBox.module.css";
import { useMedia } from "../../contexts/MediaContext";

export const MovieEditionBox = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    let mediaItem = useMedia();

    // ----------------------------------------------------- PRE-RENDER

    // formats date
    let date = "";
    if (mediaItem && mediaItem.release) {
        const temp = new Date(mediaItem.release)
        date = new Date(temp.getTime() - temp.getTimezoneOffset() * -60000).toLocaleString('en-us', { day: 'numeric', month: 'long', year: 'numeric' });
    }

    // formats length from min to hrs & min
    let length = "";
    if (mediaItem && mediaItem.numPages) {
        const hours = Math.floor(mediaItem.numPages / 60);
        const minutes = mediaItem.numPages % 60;

        length = hours + " hrs " + minutes + " mins";
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <h4 className={styles.header}>Length</h4>
            <div className={styles.value}>{length}</div>

            <h4 className={styles.header}>Parent Guide</h4>
            <div className={styles.value}>{mediaItem.parentguied}</div>

            <h4 className={styles.header}>Release Date</h4>
            <div className={styles.value}>{date}</div>

            {mediaItem.genres.length > 0 ?
                <div>
                    <h4 className={styles.header}>Genre:</h4>
                    <div className={styles.genreList}>{mediaItem.genres.map((g) => <div className={styles.genre} key={g.genreID}>{g.genreNAME}</div>)}</div>
                </div>
                :
                null
            }

        </section>
    )
}