import React, { useEffect, useState } from "react";

import styles from "./Reading.module.css";
import { useMedia } from "../../contexts/MediaContext";

export const Reading = (props) => {
    
    // props:
    //
    // readingItem - all reading information (isbn, dateStarted, dateFinished)
    // isEditing - state var, if in edit mode
    // editedReadings - array of readings that have been edited
    // toBeDeleted - state var setter for if delete button is pressed

    // ----------------------------------------------------- STATE AND HOOKS

    const [editStart, setEditStart] = useState(props.readingItem.dateStarted);
    const [editFinish, setEditFinish] = useState(props.readingItem.dateFinished);

    const [stagedForDelete, setStagedForDelete] = useState(false); // state fake for deleting

    const canDelete = (props.readingItem.entries !== null && props.readingItem.entries.length > 0) ? false : true;

    const mediaItem = useMedia();
    
    // ----------------------------------------------------- PRE-RENDER 
    let reading = [] 
    
    let start = makeDate(props.readingItem.dateStarted);
    let finish = makeDate(props.readingItem.dateFinished)

    reading.push(start);
    reading.push(finish);

    // prevents reading from starting/ending on a day that has not happened yet
    const today = new Date();
    const formmattedToday = today.toISOString().slice(0, 10);

    function makeDate(date) {
        // formats date in human readable way
        if (date === null) { // if reading in progress
            return "Present"
        }

        const temp = new Date(date)
        const converted = new Date(temp.getTime() - temp.getTimezoneOffset() * -60000).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric' });

        return converted;
    }

    const handleEditValue = (event) => {
        // handles change to value

        let changedReadingID = event.target.id.split(",");

        let readingInfo = {
            readingID : Number(changedReadingID[0]),
            start : null,
            end : null
        }

        if (changedReadingID[1] === "start" ) {
            readingInfo.start = new Date(event.target.value.replaceAll("-", "/")); // accounts for differnet ways js interperates dates
            setEditStart(event.target.value);
        }
        else {
            readingInfo.end = new Date(event.target.value.replaceAll("-", "/"));
            setEditFinish(event.target.value);
        }

        if (props.editedReadings.length !== 0) {
            // checks to see if component was already changed
            let found = false;
            for (let i = 0; i < props.editedReadings.length; i++) {
                if (props.editedReadings[i].readingID === Number(changedReadingID[0])) {
                    // removes old change and adds new
                    props.editedReadings.splice(i, 1);
                    props.editedReadings.push(readingInfo)

                    found = true;
                }
            }
            // adds new change
            if (!found) props.editedReadings.push(readingInfo)
            
        } else {
            // adds first change
            props.editedReadings.push(readingInfo)
        }

    }

    function handleDelete() {
        // initiates removal of a reading if reading has no entries
        if (canDelete) {
            setStagedForDelete(true);
            props.setToBeDeleted(props.readingItem.readingID);
        }
    }
    
    // ----------------------------------------------------- RENDER
    if (!props.isEditing && !stagedForDelete) {
        return (
            <div className={styles.container}>
                <h1 className={styles.dates}>{reading[0]}</h1>
                <h1 className={styles.hyphen}> - </h1>
                <h1 className={styles.dates}>{reading[1]}</h1>
            </div>
        );
    } else if (!stagedForDelete) {
        return (
            <div key={props.readingItem.readingID} className={styles.editedReading}>
                <input type="date" id={props.readingItem.readingID + ",start"} className={styles.dateInputStart} value={editStart} onChange={handleEditValue} max={formmattedToday}/>
                <h1 className={styles.editinghyphen}>-</h1>
                {props.readingItem.readingID !==  mediaItem.currentReading?.readingID ?
                    <input type="date" id={props.readingItem.readingID + ",end"} className={styles.dateInputFinish} value={editFinish} onChange={handleEditValue} max={formmattedToday}/>
                    :
                    <div className={styles.presentReading}>Present</div>
                }
                <button className={styles.deleteButton} onClick={handleDelete}><img alt="delete reading button" className={ canDelete ?   styles.deleteIcon : styles.deleteIconGray} /></button>
            </div>
        );
    } else {
        // state fake for deleting
        return (null);
    }
}