import React from "react";
import styles from "./SongList.module.css";
import { useMedia } from "../../contexts/MediaContext";

import { Song } from "./Song";

export const SongList = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    let mediaItem = useMedia();

    const songs = makeSongs();

    // ----------------------------------------------------- PRE-RENDER

    function makeSongs() {
        // makes song items

        let sortedSongs = mediaItem.submedia.sort(function (a,b) {
            // makes sure songs are sorted
            if (a.overallnum > b.overallnum) return 1;
            else return -1;
        } )

        // makes objects
        let temp = [];
        for (let i = 0; i < sortedSongs.length; i++) {
            temp.push(
                <Song songItem={sortedSongs[i]} key={sortedSongs[i].submediaID}/>
            );
        }
        return temp;
    }
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.content}>
                {songs}
            </div>
        </section>
    )
}