import React, { useEffect, useState } from "react";
import { useSearchParams } from 'react-router-dom';

import styles from "./CatalogBanner.module.css";

import { getCatalogPage, getCatalogTypePage, getGenreCatalogPage, getGenreCatalogTypePage, getGenres } from "../../api/Api";
import { CatalogBook } from "../BookTypes/CatalogBook/CatalogBook";
import { Loader } from "../Loader/Loader";
import { SmallDropdown } from "../SmallDropdown/SmallDropdown";
import { SmallDropdownItem } from "../SmallDropdown/SmallDropdownItem";
import { TBRbook } from "../BookTypes/TBRBook/TBRBook";
import ScrollToTop from "../../helpers/ScrollToTop";

export const CatalogBanner = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");

    const [searchParams, setSearchParams] = useSearchParams({ "page": "1" }); // saves page to url
    const page = searchParams.get("page"); // page value
    const [mediaSize, setMediaSize] = useState(localStorage.getItem("catalogBookSize"));

    const [selectedType, setSelectedType] = useState(null);
    const [selectedGenre, setSelectedGenre] = useState(null);

    const [genres, setGenres] = useState([]);

    const [mediaItems, setmediaItems] = useState([]);

    let types = [
        // Display values for types
        "Albums",
        "Books",
        "Movies",
        "TV Shows"        
    ]

    const typeObjects = makeTypeObjects();
    const genreObjects = makeGenreObjects();
    const mediaObjects = makeMediaObjects();

    let options = [
        // Options for cover size drop down
        <SmallDropdownItem key="cover" displayValue="Cover" onClick={() => changeBookSize("cover")} />,
        <SmallDropdownItem key="exp" displayValue="Expanded" onClick={() => changeBookSize("exp")} />,
    ]
    let sizeFromLocalStroageDisplayOptions = {
        // Options for converting local storage value to display value
        null: "Expanded",
        "exp": "Expanded",
        "cover": "Cover",
    }
    let colomnNumbersPerBookSize = {
        // Options for converting local storage value to content styling 
        null: styles.contentEXP,
        "exp": styles.contentEXP,
        "cover": styles.contentCOVER,
    }


    let typeDict = {
        // mappings of display values to backend values
        "TV Shows" : "tvshow",
        "Books" : "book",
        "Albums" : "album",
        "Movies" : "movie"
    }

    const [showEmptyShelfMessage, setShowEmptyShelfMessage] = useState(false);
    const [lastPage, setLastPage] = useState(false);


    useEffect(() => {
        // runs on first render, gets genres from back 
        getGenresFromBack();
    }, []);

    useEffect(() => {
        // gets media page on first render and when page changes
        if (selectedType === null) fetchMediaPage();
        else fetchMediaTypePage();

        ScrollToTop();

    }, [page, selectedGenre, selectedType])

    useEffect(() => {
        // will trigger a rerender when mediaSize changes thus remaking media objects
    }, [mediaSize]);

    // ----------------------------------------------------- PRE-RENDER


    async function fetchMediaPage() {
        // gets a page of media from the back NOT filtered by type

        let pageSize = 12;

        if (selectedGenre === null) {
            // pulls from all catalog media

            await getCatalogPage(page, pageSize, token)
                .then((foundMedia) => {
                    if (foundMedia.length === 0) {
                        // reached end of database
                        setShowEmptyShelfMessage(true);
                    } else {
                        if (foundMedia.length < pageSize) {
                            setLastPage(true);
                        } else {
                            setLastPage(false);
                        }
                        setShowEmptyShelfMessage(false);
                        makeMediaObjects(foundMedia);
                        setmediaItems(foundMedia);
                    }
                })
                .catch((error) => {
                    console.log("failed to fetch catalog page");
                    console.log(error);
                })
        } else {
            // pulls from catalog to match selected genre

            await getGenreCatalogPage(page, pageSize, selectedGenre.genreID, token)
                .then((foundMedia) => {
                    if (foundMedia.length === 0) {
                        // reached end of database
                        setShowEmptyShelfMessage(true);
                    } else {
                        if (foundMedia.length < pageSize) {
                            setLastPage(true);
                        } else {
                            setLastPage(false);
                        }

                        setShowEmptyShelfMessage(false);
                        makeMediaObjects(foundMedia);
                        setmediaItems(foundMedia);
                    }
                })
                .catch((error) => {
                    console.log("failed to fetch catalog genre page");
                    console.log(error);
                })
        }
    }

    async function fetchMediaTypePage() {
        // gets page of media filtered by type (and genre if selected)

        let pageSize = 12;

        if (selectedGenre === null) {
            // genre NOT selected
            await getCatalogTypePage(page, pageSize, typeDict[selectedType], token)
                .then((foundMedia) => {
                    if (foundMedia.length === 0) {
                        // reached end of database
                        setShowEmptyShelfMessage(true);
                    } else {
                        if (foundMedia.length < pageSize) {
                            setLastPage(true);
                        } else {
                            setLastPage(false);
                        }

                        setShowEmptyShelfMessage(false);
                        makeMediaObjects(foundMedia);
                        setmediaItems(foundMedia);
                    }
                })
                .catch((error) => {
                    console.log("failed to fetch catalog type page");
                    console.log(error);
                })
        } else {
            // genre selected
            await getGenreCatalogTypePage(page, pageSize, selectedGenre.genreID, typeDict[selectedType], token)
                .then((foundMedia) => {
                    if (foundMedia.length === 0) {
                        // reached end of database
                        setShowEmptyShelfMessage(true);
                    } else {
                        if (foundMedia.length < pageSize) {
                            setLastPage(true);
                        } else {
                            setLastPage(false);
                        }

                        setShowEmptyShelfMessage(false);
                        makeMediaObjects(foundMedia);
                        setmediaItems(foundMedia);
                    }
                })
                .catch((error) => {
                    console.log("failed to fetch catalog genre type page");
                    console.log(error);
                })
        }
    }

    async function getGenresFromBack() {
        // gets genre objects from back

        await getGenres(token)
            .then((foundGenres) => {
                setGenres(foundGenres);
            })
            .catch((error) => {
                console.log("failed to get genres from back");
                console.log(error);
            })

    }

    function incPage() {
        // increases page by one as caused by next button on bottom of page
        let newPage = Number(page) + 1;
        if (showEmptyShelfMessage) {
            return;
        }
        setSearchParams(prev => {
            prev.set("page", newPage)
            return prev
        }, { replace: true });

        ScrollToTop();
    }

    function decPage() {
        // decreases page by one as caused by back button on bottom of page
        let newPage = Number(page) - 1;
        if (newPage < 1) {
            return;
        }
        setSearchParams(prev => {
            prev.set("page", newPage)
            return prev
        }, { replace: true });

        ScrollToTop();
    }

    function makeMediaObjects() {
        // makes media objects

        let tempList = [];
        if (mediaSize === null || mediaSize === "exp") {
            tempList = mediaItems.map((media) =>
                <CatalogBook mediaItem={media} key={media.mediaID} />
            )
        } else if (mediaSize === "cover") {
            tempList = mediaItems.map((media) =>
                <TBRbook mediaItem={media} key={media.mediaID} />
            )
        }
        return tempList;
    }

    function makeGenreObjects() {
        // makes genre objects

        let temp = [];
        for (let i = 0; i < genres.length; i++) {
            temp.push(<button className={selectedGenre?.genreID === genres[i].genreID ? styles.activeGenreOrType : styles.inactiveGenreOrType}
                key={i}
                onClick={() => handleGenreClick(i)}>
                {genres[i].genreNAME}
            </button>)
        }
        return temp;
    }

    function makeTypeObjects() {
        // creates objects that allow selecting of type to filter by

        let temp = [];
        for (let i = 0; i < types.length; i++) {
            temp.push(
                <button className={selectedType === types[i] ? styles.activeGenreOrType : styles.inactiveGenreOrType}
                    key={"typeselector"+i}
                    onClick={ () => handleTypeClick(i) }>
                        {types[i]}
                    </button>
            )
        }
        return temp;
    }

    function changeBookSize(newSize) {
        // changes book size

        localStorage.setItem("catalogBookSize", newSize);
        setMediaSize(newSize);
    }

    function handleGenreClick(newGenre) {
        // handles a change to selected genre

        // resets page to 1
        setSearchParams(prev => {
            prev.set("page", 1)
            return prev
        }, { replace: true });

        if (genres[newGenre].genreID === selectedGenre?.genreID) {
            // unselectes a genre if the currently selected genre is clicked again
            setSelectedGenre(null);

        } else {
            setSelectedGenre(genres[newGenre]);
        }
    }

    function handleTypeClick(typeIndex) {
        // sets selected type 
        if (types[typeIndex] === selectedType) {
            // unselects type if clicked again
            setSelectedType(null);
        } else {
            setSelectedType(types[typeIndex]);
        }
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.header}>
                <div> Catalog </div>
                <div className={styles.pageSelector}>
                    {Number(page) !== 1 ?
                        <button onClick={decPage} className={styles.carrotBackButton}><img className={styles.carrot} src="/assets/images/backIcon.svg" alt="dec page button" /></button>
                        :
                        null
                    }
                    {!showEmptyShelfMessage && !lastPage ?
                        <button onClick={incPage} className={styles.carrotNextButton}><img className={styles.carrot} src="/assets/images/nextIcon.svg" alt="inc page button" /></button>
                        :
                        null
                    }

                </div>
            </div>
            <div className={styles.sizeSelector}>
                <SmallDropdown buttonText={`Book Size: ${sizeFromLocalStroageDisplayOptions[mediaSize]}`} content={options} />
            </div>
            <div className={styles.typeContainer}>
                {typeObjects}
            </div>
            <div className={styles.genreContainer}>
                {genreObjects}
            </div>
            <div className={colomnNumbersPerBookSize[mediaSize]} >
                {showEmptyShelfMessage ?
                    <div className={styles.emptyShelf}>You've reached the end of the shelf</div>
                    :
                    mediaObjects
                }
            </div>
            <div className={styles.bottomPageSelector}>
            {Number(page) !== 1 ?
                <button onClick={decPage} className={styles.carrotBackButton}><img className={styles.carrot} src="/assets/images/backIcon.svg" alt="dec page button" /></button>
                :
                <div />
            }
            {!showEmptyShelfMessage && !lastPage ?
                <button onClick={incPage} className={styles.carrotNextButton}><img className={styles.carrot} src="/assets/images/nextIcon.svg" alt="inc page button" /></button>
                :
                <div />
            }
            </div>
        </section>
    )
}