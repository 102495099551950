import React, { useState, useEffect } from "react";
import styles from "./SelectedBookSuggestion.module.css";

import { Cover } from "../../Cover/Cover";
import { Author } from "../../Author/Author";

import { Link } from "react-router-dom";

export const SelectedBookSuggestion = (props) => {

    // props:
    // 
    // mediaItem - object containing book infromation

    // ----------------------------------------------------- STATE AND HOOKS

    const splitTitle = processTitle();

    const bookPath = `/${props.mediaItem.type}/` + props.mediaItem.mediaID;
    const seriesPath = "/series/" + props.mediaItem.series?.id;

    // ----------------------------------------------------- PRE-RENDER

    function processTitle() {
        // splits title into two peices and adds star to second to prevent weird line wrapping
        let words = props.mediaItem.title.split(" ");
        let lastWord = words.pop();

        const firstWords = words.join(" ") + " ";

        return [firstWords, lastWord];
    }

    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.container}>
            <div className={styles.content}>
                <Cover mediaItem={props.mediaItem} size={window.screen.width > 500 ? "M" : "S"} loc="recommendationBook" />
                <div className={styles.bookInfo}>
                    <Link to={bookPath} className={styles.link}>
                        <div>
                            <span className={styles.firstWordsOfTitle}>{splitTitle[0]}</span>
                            <span className={styles.lastWordOfTitle}>{splitTitle[1]} {props.mediaItem.favorited ? <img key="star" className={styles.starIconFilled} alt="Favorite star" /> : null}</span>
                        </div>
                    </Link>
                    {props.mediaItem.series != null ?
                        <div>
                            <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{props.mediaItem.series?.name} #{props.mediaItem.seriesNum}</h3></Link>
                        </div> :
                        null
                    }
                    <Author authorsList={props.mediaItem.creators} size={1} link={true}/>
                </div>
            </div>
        </div>
    )
}