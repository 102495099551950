import React from "react";

import styles from "./SearchSeries.module.css";
import { Link } from "react-router-dom";

export const SearchSeries = (props) => {
    // props:
    //
    // seriesItem - object containing series info
    // active - bool, indicates if author is selected or not

    // ----------------------------------------------------- PRE-RENDER 

    let seriesPath = "/series/" + props.seriesItem.seriesID;

    // ----------------------------------------------------- RENDER 

    return (
        <Link to={seriesPath} className={styles.link}>
            <section className={props.active ? styles.containerActive : styles.container}>
                <div className={styles.content}>
                    <h2 className={styles.seriesName}>{props.seriesItem.name}</h2>
                </div>
            </section>
        </Link>
    )
}