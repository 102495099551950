import React, { useState, useEffect } from "react";
import styles from "./TagEditor.module.css";

import { getTags, postTag, removeTagFromUsermedia, putTagOnMedia } from "../../api/Api";

import { Tag } from "../Tag/Tag";
import { useMedia } from "../../contexts/MediaContext";

export const TagEditor = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    const mediaItem = useMedia();
    let token = localStorage.getItem("token");

    const [isEditingTag, setIsEditingTag] = useState(false);
    const [thisObjectTags, setThisObjectTags] = useState(mediaItem.tags);
    const [allTags, setAllTags] = useState([]);

    const [showError, setShowError] = useState(null);

    const tags = thisObjectTags !== null ?  
        thisObjectTags.map((tag, index) =>
            <li key={index} className={styles.tag}>
                <Tag tagItem={tag} deleteButtonClick={handleRemoveTag} isEditingTag={isEditingTag} />
            </li>
        ) 
        : 
        null;
      
    useEffect(() => {
        // gets tags on first render
        fetchTags();
    }, [])

    // ----------------------------------------------------- PRE-RENDER

    const updateTagEditing = () => {
        //toggles tag edditng box
        setShowError(null)
        setIsEditingTag(!isEditingTag)
    }

    function addTag(event) {
        //adds tag to backend
        if (event.key === "Enter" && event.target.value !== "") { // if user intentionally sends
            setShowError(null);
            if (event.target.value.length > 50) { // doesn't allow adding of tag more than 50 characters
                setShowError("Body of tag is too long, please try again")
            } else {
                // checks to see if object already has tag
                let objectContains = false;
                thisObjectTags.map((tag) => {
                    if (tag.tagValue === event.target.value) {
                        objectContains = true;
                        setShowError("That tag is already associated with this book.")
                    }
                });

                // checks to see if any object already has tag
                let allContains = false;
                let tagId = 0;
                allTags.map((tag) => {
                    if (tag.tagValue === event.target.value) {
                        allContains = true;
                        tagId = tag.tagID
                    }
                });

                if (allContains && !objectContains) { // if found tag in allTags, adds it to book in backend
                    addTagToObject(tagId);
                } else if (!allContains) { // entirly new tag, add it to tag table and then to spesific object
                    addTagToTags(event.target.value);
                }

                event.target.value = "";
            }
        }
    }

    function handleRemoveTag(tag) {
        // handles a click to the x button of a tag
        setShowError(null)
        removeTagFromDB(tag.tagID);
    }

    // ---------------------------- FUNCTIONS THAT CONNECT TO BACKEND

    async function fetchTags() {
        // gets all tags
        await getTags(token)
            .then((tags) => {
                setAllTags(tags);
            })
            .catch((error) => {
                console.log("failed to get tags, oh no")
                console.log(error)
            })
    }

    async function addTagToObject(tagID) {
        // adds existing tag to existing media
        await putTagOnMedia(mediaItem.usermediaID, tagID, token)
                .then((book) => {
                    setThisObjectTags(book.tags);
                })
                .catch((error) => {
                    console.log("failed to add tag to book");
                    console.log(error.message);

                    if (error.message == "Request failed with status: 400") {
                        setShowError("That tag is already associated with this book");
                    }
                })
    }

    async function addTagToTags(tagValue) {
        // adds new tag to tag table
        await postTag(tagValue, token) // adds a new tag to the tag resource collection
            .then((tag) => {
                console.log("added", tag)
                addTagToObject(tag.tagID);
                setAllTags([...allTags, tag]); // adds new tag to total tags
            })
            .catch((error) => {
                console.log("failed to add tag to tags");
                console.log(error.message)
            })
    }

    async function removeTagFromDB(tagId) {
        //removes tag from media
        await removeTagFromUsermedia(mediaItem.usermediaID, tagId, token)
                .then(
                    setThisObjectTags([...thisObjectTags.filter(tag => tag.tagID !== tagId)])
                )
                .catch((error) => {
                    console.log("failed to remove tag from database")
                    console.log(error)
                })
    }

    // ----------------------------------------------------- RENDER

    return (
        <div className={styles.tagContainer}>
            <div className={styles.titleAndButton}>
                <h3 className={styles.infoTitle}>Tags</h3>
                <button
                    className={styles.editButton}
                    onClick={updateTagEditing}>
                    <img src="/assets/images/editIcon.svg"
                        className={styles.editButtonImage}
                        alt="Edit Icon" />
                </button>
            </div>
            <div className={styles.directTagContainer}>
                <ul className={styles.tagList}>
                    {tags}
                </ul>
                <div>
                    <h5 className={styles.errorMessage}>{showError}</h5>
                    <input className={styles.tagInput} key="tag input"
                        type="text"
                        autoFocus={false}
                        list='tagSuggestion'
                        placeholder="Press enter to add tag"
                        onKeyUp={event => addTag(event)} />
                    <datalist id="tagSuggestion">
                        {allTags.map((tag, index) => {
                            return (<option key={index} value={tag.tagValue}>{tag.tagValue}</option>)
                        })}
                    </datalist>
                </div>
                
            </div>
        </div>
    );
}