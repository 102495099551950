import React, { useEffect, useState } from "react";
import styles from "./SeriesBanner.module.css";

import { SeriesWorkBook } from "../BookTypes/WorkTypes/SeriesWorkBook";
import { useMediaUpdate } from "../../contexts/MediaContext"

export const SeriesBanner = (props) => {
    
    // props:
    //
    // seriesItem - object containing series id and name

    // ----------------------------------------------------- STATE AND HOOKS

    const [isLoaded, setIsLoaded] = useState(false);
    const mediaUpdater = useMediaUpdate();

    useEffect(() => { // prevents loading before data has arrived
        if (Object.keys(props.seriesItem).length !== 0) {
            setIsLoaded(true);
        }
    }, [props.seriesItem])

    useEffect(() => { // clears book to prevent flash of pervious one
        mediaUpdater({});
    }, [])

    // ----------------------------------------------------- PRE-RENDER

    let workObjects = [];

    if (props.seriesItem.mediaInSeries && props.seriesItem.mediaInSeries.length !== 0) {
        // sorts books by series number
        let sortedSeriesBooks = props.seriesItem.mediaInSeries.sort(function (a, b) {
            if (a.seriesNum > b.seriesNum) {
                return 1;
            }
            if (a.seriesNum < b.seriesNum) {
                return -1;
            }
            return 0;
        });

        // makes book objects
        for (let i = 0; i < sortedSeriesBooks.length; i++) {
            workObjects.push(<div key={i}><SeriesWorkBook mediaItem={sortedSeriesBooks[i]} /></div>);
        }
    } else if (props.seriesItem.tvshowsInSeries && props.seriesItem.tvshowsInSeries.length !== 0) {
        // sorts tv shows by start date
        let sortedSeriesTVShows = props.seriesItem.tvshowsInSeries.sort(function(a, b) {
            if (a.startyear < b.startyear) {
                return 1
            }
            if (a.startyear > b.startyear) {
                return -1
            }
            return 0
        });

        for (let i = 0; i < sortedSeriesTVShows.length; i++) {
            workObjects.push(<div key={i}>{sortedSeriesTVShows[i].title}</div>)
        }
    }

    // ----------------------------------------------------- RENDER

    if (!isLoaded) {
        return (
            <div></div>
        )
    } else {
        return (
            <div className={styles.container}>
                <div className={styles.content}>
                    <h1 className={styles.seriesName}>{props.seriesItem.name}</h1>
                    <div className={styles.workContainer}>{workObjects}</div>
                </div>
            </div>
        );
    }
}