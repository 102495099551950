import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import styles from "./TagObject.module.css";
import { Tag } from "../../Tag/Tag";
import { Cover } from "../../Cover/Cover";
import { Author } from "../../Author/Author";

export const TagBook = (props) => {
    // book object that shows cover, title, series, author, and tags

    // props:
    //
    // mediaItem - book object
    
    // ----------------------------------------------------- STATE AND HOOK

    const [isOpen, setIsOpen] = useState(false); //state of truncated review text block, open/close

    const tagObjects = makeVisableTags();
    // const visiableTags = makeVisableTags();

    const bookPath = `/${props.mediaItem.type}/` + props.mediaItem.mediaID;
    const seriesPath = "/series/" + props.mediaItem.series?.seriesID;

    let seriesDisplayString = (props.mediaItem.series !== null && props.mediaItem.seriesNum !== null) ? 
                                                            props.mediaItem.series?.name + " #" + props.mediaItem.seriesNum 
                                                            : 
                                                            props.mediaItem.series?.name;

    useEffect(()=> {
        // triggers rerender when open status changes
    },[isOpen])
    

    // ----------------------------------------------------- PRE-RENDER

    function toggleTags () {
        // toggles the visiable tags
        setIsOpen(!isOpen);
    }

    function makeVisableTags() {
        // selectes which tags are visiable
        if (props.mediaItem.tags === null) return; // NO tags

        let temp = []
        if (window.screen.width > 500 && props.mediaItem.tags.length > 2 && !isOpen) {
            // DESKTOP, closed
            // show first 2
            for (let i = 0; i < 2; i++) {
                temp.push(<Tag tagItem={props.mediaItem.tags[i]} key={props.mediaItem.mediaID + "tag"+i} />)
            }
            return temp;
        } else if (window.screen.width < 500 && props.mediaItem.tags.length > 1 && !isOpen) {
            // MOBILE, closed
            // show first 1
            for (let i = 0; i < 1; i++) {
                temp.push(<Tag tagItem={props.mediaItem.tags[i]} key={props.mediaItem.mediaID + "tag"+i} />)
            }
            return temp;
        } else {
            // shows all tags 
            // isOpen or correct number of tags for window size naturally
            for (let i = 0; i < props.mediaItem.tags.length; i++) {
                temp.push(<Tag tagItem={props.mediaItem.tags[i]} key={props.mediaItem.mediaID + "tag"+i} />)
            }
            return temp;
        }
    }

    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <div className={styles.content}> 
                <Cover mediaItem = {props.mediaItem} size = {window.screen.width > 500 ? "L" : "M"} loc="tagBook"/>
                <div className={styles.bookInfo}>   
                    <Link to={bookPath} className={styles.link}><h2 className={styles.bookTitle}>{props.mediaItem.title}</h2></Link>
                    {props.mediaItem.series !== null ?
                        <div>
                            <Link to={seriesPath} className={styles.link}><h3 className={styles.bookSeries}>{seriesDisplayString}</h3></Link>
                        </div> 
                        :
                        null
                    }
                    <Author authorsList={props.mediaItem.creators} size={window.screen.width > 500 ? 1 : 0} link={true} />
                    <div className={styles.tags}>
                        <div className={isOpen ? styles.tagsInBook : styles.tagsInBookClosed}>{tagObjects}</div>
                        { (window.screen.width > 500 && props.mediaItem.tags.length > 2) ||
                          (window.screen.width < 500 && props.mediaItem.tags.length > 1) ? 
                            <button className={styles.readMoreOrLessButton}
                                    onClick={ () => toggleTags() }>
                                    {isOpen ?
                                        <img className={styles.carrot} src="/assets/images/upCarrot.svg" alt="read less" />
                                        :
                                        <img className={styles.carrot} src="/assets/images/downCarrot.svg" alt="read more" />
                                    }
                            </button>
                            : 
                            null
                        }
                    </div>
                </div>
            </div>
            
        </section>
    );
}