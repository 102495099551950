import React from "react";
import styles from "./EditionBox.module.css";
import { useMedia } from "../../contexts/MediaContext";

export const VideogameEditionBox = () => {

    // ----------------------------------------------------- STATE AND HOOKS
    let token = localStorage.getItem("token");
    const mediaItem = useMedia();

    // ----------------------------------------------------- PRE-RENDER
    // formats date
    let date = "";
    if (mediaItem && mediaItem.release) {
        const temp = new Date(mediaItem.release)
        date = new Date(temp.getTime() - temp.getTimezoneOffset() * -60000).toLocaleString('en-us', { day: 'numeric', month: 'long', year: 'numeric' });
    }
    // ----------------------------------------------------- RENDER

    return (
        <section className={styles.container}>
            <h4 className={styles.header}>Published:</h4>
            <div className={styles.value}>{date}</div>

            <h4 className={styles.header}>Model:</h4>
            <div className={styles.value}>{mediaItem.numPages === 1 ? "Single Player" : "Multi-Player"}</div>

            {mediaItem.genres.length > 0 ?
                <div>
                    <h4 className={styles.header}>Genre:</h4>
                    <div className={styles.genreList}>{mediaItem.genres.map((g) => <div className={styles.genre} key={g.genreID}>{g.genreNAME}</div>)}</div>
                </div>
                :
                null
            }
            
        </section>
    )
}